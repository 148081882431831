@import './vars/colors.css';
@import '~assets/styles/vars/fonts.scss';

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  @extend %Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: #1b222e;
  overflow-y: scroll;
}

button {
  cursor: pointer;
  outline: none;
}

button,
input,
textarea {
  font-family: inherit;
}

.container {
  max-width: 1270px;
  padding: 0 15px;
  margin: 0 auto;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 0.8s linear infinite;
}
