:root {
  --color-white: #FFFFFF;
  --color-black: #1B222E;

  --color-light-grey: #EAEDF4;
  --color-dark-grey: #3e4757;

  --color-light-blue: #F4F6FC; 
  --color-blue: #335DCA;

  --color-pink: #e94f87;

  --color-primary: var(--color-blue);
  --color-primary-dark: var(--color-black);
  --color-second-light: var(--color-light-blue);
  --color-main-border: var(--color-light-grey);
  --color-dark-text: var(--color-dark-grey);
  --color-error: var(--color-pink);
}